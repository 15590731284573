import Vue from 'vue'
import Vuex from 'vuex'

import { authenticationStore } from './authentication'
import { frontofficeStore } from './frontoffice'
import { leagueStore } from './league'
// import { scoreboardStore } from './scoreboard'
import { sidepotsStore } from './sidepots'
import { mainStore } from './main'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authenticationStore,
    frontofficeStore,
    leagueStore,
    sidepotsStore,
    mainStore,
  },
})
