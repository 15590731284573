import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        accent: '#f71a1a',
        background: '#1e1e1e',
        info: '#2196F3',
        error: '#D50000',
        primary: '#5461ee',
        // primary: '#1629ec',
        secondary: '#fff',
        success: '#4CAF50',
        warning: '#FFC107',
        disabled: '#545b77',
      },
      light: {
        accent: '#D50000',
        background: '#c4c4c4',
        info: '#2196F3',
        error: '#D50000',
        primary: '#1A237E',
        secondary: '#fff',
        success: '#4CAF50',
        warning: '#FFC107',
        disabled: '#545b77',
      },
    },
  },
})
