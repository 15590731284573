import axios from 'axios'
import Vue from 'vue'
import axiosMixins from '@/mixins/api.js'

export const authenticationStore = {
  mixins: [axiosMixins],
  namespaced: false,
  state: {
    baseUrl: process.env.VUE_APP_API_URL || '',
    isAuth: null,
    firstLoad: true,
    loading: true,
    siteStatus: null,
    siteStatusMessage: '',
    userData: {},
  },
  getters: {
    baseUrl(state) {
      return state.baseUrl
    },
    isAuth(state) {
      return state.isAuth
    },
    firstLoad(state) {
      return state.firstLoad
    },
    user(state) {
      return state.userData
    },
  },

  mutations: {
    firstLoad(state) {
      state.firstLoad = false
    },
    setAuth(state, data) {
      state.isAuth = data
    },
    setStatus(state, data) {
      state.siteStatus = data.status
      state.siteStatusMessage = data.message
    },
    setUserData(state, data) {
      state.userData = data
    },
    setUserDataItem(state, data) {
      Vue.set(state.userData, data.item, data.value)
    },
    toggleUserSetting(state, data) {
      state.userData.userSettings[data.key].value = data.value
    },
  },

  actions: {
    initializeApp(context) {
      return new Promise(
        (resolve, reject) => {
          import('./main')
            .then(({ mainStore }) => {
              if (!context.rootState.mainStore) {
                this.registerModule('mainStore', mainStore)
              }
            })
            .then(() => {
              context.dispatch('initializeData').then(resp => {
                //SET isAuth to True in Store, then local state as back up (if closed and session saved)
                context.commit('setAuth', true)
                resolve('success')
              })
            })
        },
        error => {
          reject(error)
        },
      )
    },
    destroyApp(context) {
      //UNLOAD ALL MODULES
      context.commit('setAuth', false)
      // context.commit('firstLoad', true)
      context.commit('setUserData', {})
      Object.keys(context.rootState).forEach(mod => {
        if (mod !== 'authenticationStore') {
          this.unregisterModule(mod)
        }
      })
    },
    firstLoad(context) {
      context.commit('firstLoad')
    },
    loginUser(context, data) {
      return new Promise(
        (resolve, reject) => {
          //SET URI ENDPOINT (ONE FOR TESTING, ONE FOR PRODUCTION)
          //TODO MAYBE AUTOMATE THIS?
          const uri = context.state.baseUrl + 'index.php/auth'
          // const uri = 'https://www.wosfl.com/auth'
          // const uri = 'https://www.wosfl.com/dev/auth'

          //SET POST PARAMS
          const params = new URLSearchParams()
          params.append('authcode', data.authcode)
          params.append('login', data.login)
          params.append('password', data.password)
          params.append('rememberMe', data.rememberMe)
          //MAKE SURE AXIOS SENDS COOKIES SINCE IT LOVES TO NOT
          //EVEN THO WE SET IT IN THE OPTIONS PASSED....
          axios.defaults.withCredentials = true
          axios.post(uri, params, axiosMixins.axiosDefaultPostOptions).then(response => {
            if (response.data.status === 'success') {
              //IF LOGIN SUCCESS, Set UserData, IMPORT mainStore Module and initialize App Data
              context.commit('setUserData', response.data.data)

              context.dispatch('initializeApp').then(() => {
                resolve(response)
              })
            } else {
              resolve(response)
            }
          })
        },
        error => {
          reject(error)
        },
      )
    },
    logoutUser(context, data) {
      return new Promise((resolve, reject) => {
        //SET URI ENDPOINT (ONE FOR TESTING, ONE FOR PRODUCTION)
        //TODO MAYBE AUTOMATE THIS?
        const uri = context.state.baseUrl + 'auth/logout'
        //MAKE SURE AXIOS SENDS COOKIES SINCE IT LOVES TO NOT
        //EVEN THO WE SET IT IN THE OPTIONS PASSED....
        axios.defaults.withCredentials = true
        axios
          .get(uri, axiosMixins.axiosDefaultOptions)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(error)
          })
      })
    },
    requestAuthcode(context, data) {
      return new Promise((resolve, reject) => {
        let uri = context.state.baseUrl + 'auth/authcode_get'

        //SET POST PARAMS
        const params = new URLSearchParams()
        params.append('login', data)
        axios.defaults.withCredentials = true
        axios.post(uri, params, axiosMixins.axiosDefaultPostOptions).then(
          response => {
            if (response) {
              resolve(response.data)
            }
          },
          error => {
            reject(error)
          },
        )
      })
    },
    resetPassword(context, { userid, password }) {
      return new Promise((resolve, reject) => {
        let uri = context.state.baseUrl + 'auth/password'
        //SET POST PARAMS
        const params = new URLSearchParams()
        params.append('password', password)
        params.append('userid', userid)
        axios.defaults.withCredentials = true
        axios.post(uri, params, axiosMixins.axiosDefaultPostOptions).then(
          response => {
            if (response) {
              resolve(response.data)
            }
          },
          error => {
            reject(error)
          },
        )
      })
    },
    setAuth(context, data) {
      context.commit('setAuth', data)
    },
    testAuth(context, data) {
      // console.log(process.env)
      return new Promise((resolve, reject) => {
        let uri = context.state.baseUrl + 'auth/testAuth/'
        // console.log(uri)
        axios.defaults.withCredentials = true
        axios.get(uri, axiosMixins.axiosDefaultOptions).then(
          response => {
            // console.log(response)
            //TODO: FIX THIS, MAKE MORE GENERIC TO HANDLE DIFF. STATUSES
            if (response.data.status === 'maint') {
              context.commit('setAuth', true)
              context.commit('setStatus', { status: response.data.status, message: response.data.message })
              resolve(response)
            }

            if (response.data.status === 'success') {
              context.commit('setUserData', response.data.userData)

              if (context.state.firstLoad) {
                context
                  .dispatch('initializeApp')
                  .then(() => {
                    const userData = {
                      state: true,
                      userData: response.data.userData,
                    }

                    resolve(response)
                  })
                  .catch(err => {
                    console.log(err)
                  })
              } else {
                resolve(response)
              }
            } else {
              console.log('no succ')
              resolve(response)
            }
          },
          error => {
            reject(error)
          },
        )
      })
    },
    updateUserData({ commit, dispatch, rootState, state }, data) {
      //Data expected to be an array of objects
      //Objects expected to have item: value
      //where item = userData key
      if (data.length && data.length > 0) {
        data.forEach(el => {
          commit('setUserDataItem', el)
        })
      } else {
        console.log('ERROR: updateUserData Expects an ARRAY')
        return
      }
      if (rootState.mainStore && rootState.mainStore.usersData) {
        let newUsersData = { ...rootState.mainStore.usersData }
        newUsersData[state.userData.id] = { ...state.userData }
        dispatch('usersDataSetData', newUsersData)
      }
    },
    updateUserSettingToggle({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let key = state.userData.userSettings.findIndex(setting => setting.setting_id === data.setting)
        commit('toggleUserSetting', { key: key, value: data.value })
        resolve(true)
      })
    },
  },
}
