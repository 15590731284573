var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-main', {
    staticClass: "overflow-hidden"
  }, [_c('v-container', {
    staticClass: "login d-flex flex-column align-center justify-start"
  }, [_c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-img', {
    staticClass: "logo flex-shrink-1",
    attrs: {
      "contain": "",
      "height": "auto",
      "width": "384",
      "src": _vm.isAuth ? "".concat(_vm.media_source, "/images/avatars/").concat(_vm.userData.avatar) : "".concat(_vm.media_source, "/images/wosfl_logo.svg")
    }
  })], 1), _c('LoginCard'), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "fullscreen": "",
      "persistent": "",
      "max-width": "400px",
      "transition": "dialog-transition"
    },
    model: {
      value: _vm.errorModal,
      callback: function callback($$v) {
        _vm.errorModal = $$v;
      },
      expression: "errorModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title primary--text align-center justify-center",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("WELCOME BACK")]), _c('v-card-text', [_c('p', [_vm._v("Welcome back to another year of WOSFL.")]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v("The login system has been updated this off season and requires you to reset your password.")]), _c('p', [_vm._v("An email has been sent with a temporary, time sensitive authentication code.")]), _c('p', {
    staticClass: "mt-2"
  }, [_vm._v("Please close this dialog and select reset password to initiate the reset.")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.errorModal = false;
      }
    }
  }, [_vm._v("OK")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }