import axiosMixins from '@/mixins/api.js'

export const leagueStore = {
  mixins: [axiosMixins],
  namespaced: false,
  state: {
    schedules: {},
    standings: null,
    transactionsAuctions: [],
    transactionsAuctionsBids: [],
    transactionsFA: [],
    transactionsTotalsByTeam: {},
    transactionsTrades: [],
  },
  getters: {
    schedulesByWeek(state) {
      let week = 1
      let schedulesByWeek = {}
      let workingSchedules = []

      if (state.schedules) {
        for (let id in state.schedules) {
          if (!schedulesByWeek[state.schedules['id'].week]) {
            schedulesByWeek[state.schedules['id'].week] = []
          }
          schedulesByWeek[state.schedules['id'].week].push(state.schedules['id'])
        }
      }
      return schedulesByWeek
    },
    schedulesByTeam(state, getters, rootState) {
      let schedulesByTeam = []
      let team = null
      if (rootState.mainStore.filterSelectedTeam) {
        team = rootState.mainStore.filterSelectedTeam
      } else {
        team = rootState.authenticationStore.userData.id
      }
      if (state.schedules) {
        for (let id in state.schedules) {
          if (state.schedules['id'].away_team === team || state.schedules['id'].home_team === team) {
            state.schedules['id'].week = parseInt(state.schedules['id'].week)
            schedulesByTeam.push(state.schedules['id'])
          }
        }
      }
      return schedulesByTeam
    },
    schedulesFilter(state, getters, rootState) {
      if (rootState.mainStore.filterSelectedTeam) {
        return rootState.mainStore.filterSelectedTeam
      } else {
        return rootState.authenticationStore.userData.id
      }
    },
  },

  mutations: {
    initializeLeagueData(state, data) {
      if (data.schedules) {
        state.schedules = data.schedules
      }
      if (data.standings) {
        state.standings = data.standings
      }
    },
    transactionsSetData(state, data) {
      //data expects key: storeKeyUpdating, data: data being set
      state[data.key] = data.data
    },
  },
  actions: {
    initializeLeagueData(context) {
      return new Promise((resolve, reject) => {
        let uri = 'league_get_league_data'
        axiosMixins.callApi(uri).then(
          response => {
            this.commit('initializeLeagueData', response)
            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    initializeTransactionsData({ commit }) {
      return new Promise((resolve, reject) => {
        let uri = 'league_get_transactions'
        axiosMixins.callApi(uri).then(
          response => {
            // this.commit('transactionsSetData', response)
            resolve(response)
            if (response.byType) {
              const stateKeyPairings = {
                adddrop: 'transactionsFA',
                auction: 'transactionsAuctions',
                auctionbid: 'transactionsAuctionsBids',
                trade: 'transactionsTrades',
              }
              for (let i in response.byType) {
                if (response.byType[i].length > 0) {
                  commit('transactionsSetData', {
                    key: stateKeyPairings[i],
                    data: response.byType[i],
                  })
                }
              }
            }
            if (response.totals && Object.keys(response.totals).length > 0) {
              //setTotals
              commit('transactionsSetData', {
                key: 'transactionsTotalsByTeam',
                data: response.totals,
              })
            }
          },
          error => {
            reject(error)
          },
        )
      })
    },
  },
}
