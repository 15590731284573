var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.componentLoaded, _vm._b({
    tag: "component"
  }, 'component', _vm.componentLoadedData, false));

}
var staticRenderFns = []

export { render, staticRenderFns }