<template>
  <div class="d-flex flex-column align-center justify-center s">
    <v-avatar size="125" class="avatar pa-5" :style="{ 'background-color': 'white', border: `3px solid ${borderColor}` }">
      <img :src="image" class="logo" />
    </v-avatar>
  </div>
</template>

<script>
  export default {
    computed: {
      image() {
        return process.env.VUE_APP_MEDIA_URL + 'images/loading_spin.gif'
      },
      borderColor() {
        return this.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary
      },
    },
  }
</script>

<style scoped>
  .avatar {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .logo {
    max-height: 108px;
    max-width: 128px;
  }
</style>
