var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "text-center",
    attrs: {
      "flat": "",
      "color": "transparent",
      "height": _vm.height ? _vm.height : _vm.dimensionsContentHeight ? parseInt(_vm.dimensionsContentHeight.substr(0, 3)) - 20 + 'px' : '100%'
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-card', {
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "blockquote align-center font-weight-bold primary--text"
  }, [!_vm.loading && _vm.icon ? _c('v-icon', {
    staticClass: "pb-6",
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.icon ? _vm.icon : 'mdi mdi-exclamation-triangle'))]) : _vm._e(), !_vm.loading && _vm.avatar ? _c('v-avatar', {
    staticClass: "pb-6",
    attrs: {
      "size": "75"
    }
  }, [_c('img', {
    attrs: {
      "src": "https://wosfl.com/boards/download/file.php?avatar=".concat(_vm.avatar)
    }
  })]) : _vm._e(), _vm.loading ? _c('v-img', {
    attrs: {
      "height": "50",
      "contain": "",
      "src": "https://www.wosfl.com/images/loading_spin.gif"
    }
  }) : _vm._e(), _c('br'), _vm._v(" " + _vm._s(_vm.text) + " ")], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }