import axios from 'axios'
import axiosMixins from '@/mixins/api.js'
import Vue from 'vue'
import router from '@/router'
import Vue2Filters from 'vue2-filters'

export const sidepotsStore = {
  mixins: [axiosMixins, Vue2Filters.mixin],
  namespaced: false,
  state: {
    pickems: null,
    pickemsGamesData: null,
    pickemsHistory: null,
    pickemsPicks: {},
    pickemsRosters: {},
    pickemsSchedules: null,
    pickemsTotalsByWeek: null,
    positionalPool: {
      1: 'QB',
      2: 'RB',
      3: 'WR',
      4: 'TE',
      5: 'K',
      6: 'DST',
      7: 'COACH PICK',
      8: 'QB',
      9: 'RB',
      10: 'WR',
      11: 'TE',
      12: 'K',
      13: 'DST',
      14: 'COACH PICK'
    }
  },
  getters: {
    pickemsPicksCount(state) {
      return state.pickemsPicks ? Object.keys(state.pickemsPicks).length : 0
    },
    pickemsHistory(state) {
      let history = {}
      let workingHistory = []
      for (const schedKey in state.pickemsSchedules) {
        workingHistory = []
        for (const histKey in state.pickemsHistory) {
          if (state.pickemsHistory[histKey]['totals'][state.pickemsSchedules[schedKey].week]) {
            workingHistory.push({
              team: histKey,
              total: state.pickemsHistory[histKey]['totals'][state.pickemsSchedules[schedKey].week]
            })
          } else {
            workingHistory.push({ team: histKey, total: 0 })
          }
        }
      }
      return history
    },
    pickemsStandings(state) {
      // if (!state.pickemsHistory || !state.pickemsHistory.totals) {
      if (!state.pickemsHistory) {
        return []
      }
      return Vue2Filters.mixin.methods.orderBy(state.pickemsHistory, 'totals.season', -1)
    }
  },
  mutations: {
    pickemsInitializePageData(state, data) {
      // console.log('tradecenterInitializePageData mutation')
      if (data) {
        state.pickemsGamesData = data.gameData
        state.pickemsHistory = data.leagueSummary
        state.pickemsTotalsByWeek = data.leagueSummaryByWeek
        state.pickemsSchedules = data.seasonSchedule
        if (data.picks !== null) {
          state.pickemsPicks = data.picks
        }
      }
    },
    pickemsPickteam(state, data) {
      Vue.set(state.pickemsPicks, data.gameId, data.pick)
    },
    pickemsRevertChanges(state, data) {
      state.pickemsPicks = data
    },
    pickemsRostersGetRosters(state, data) {
      state.pickemsRosters = data
    }
  },
  actions: {
    bestballInitializePageData() {
      return new Promise((resolve, reject) => {
        // console.log('dfsInitializePageData')
        let uri = 'bestball_get_page_data'
        axiosMixins.callApi(uri).then(
          resp => {
            // console.log(resp)
            resolve(resp)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    pickemsInitializePageData(context) {
      return new Promise((resolve, reject) => {
        // console.log('pickemsInitializePageData')
        let uri = 'pickems_get_page_data'
        axiosMixins.callApi(uri).then(
          resp => {
            this.commit('pickemsInitializePageData', resp)
            resolve(resp)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    pickemsPickTeam({ commit }, data) {
      // console.log('pickemsPickTeam action')
      if (data['gameId'] && data['pick']) {
        commit('pickemsPickteam', data)
      }
    },
    pickemsRevertChanges({ commit, dispatch }, data) {
      if (data) {
        const originalPicks = JSON.parse(data)
        if (typeof originalPicks === 'object') {
          commit('pickemsRevertChanges', originalPicks)
          dispatch('snackbarToggle', {
            sbState: true,
            sbData: { status: 'success', message: 'picks reverted' }
          })
        } else {
          dispatch('snackbarToggle', {
            sbState: true,
            sbData: { status: 'error', message: 'error: try to refresh page' }
          })
        }
      }
    },
    pickemsRosters(context) {
      return new Promise((resolve, reject) => {
        // console.log('rostersGetRosters')
        let uri = 'rosters_get_roster/all'
        axiosMixins.callApi(uri).then(
          resp => {
            context.commit('pickemsRostersGetRosters', resp)
            resolve(resp)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    pickemsSubmitPicks({ state }) {
      return new Promise((resolve, reject) => {
        // console.log('pickemsSubmitPicks')
        let uri = 'pickems_submit'
        axiosMixins.callApi(uri, { picks: JSON.stringify(state.pickemsPicks) }).then(
          resp => {
            resolve(resp)
            // resolve({status: 'success', message: 'PICKS SUBMITTED!'})
          },
          error => {
            reject(error)
          }
        )
      })
    }
  }
}
