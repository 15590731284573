import axiosMixins from '@/mixins/api.js'
import Vue from 'vue'
export const mainStore = {
  mixins: [axiosMixins],
  namespaced: false,
  state: {
    activeGamesCount: 0,
    alerts: {},
    appVersion: '',
    bottomNav: true,
    bottomNavActive: false,
    bottomNavItems: {},
    chatChannel: 'All',
    chatMessages: [],
    chatMessageCounts: { All: 0 },
    chatVisible: false,
    contentDivMaxScrollReached: false,
    counts: null,
    dimensions: {},
    dimensionsContentHeight: null,
    divisions: null,
    filter: false,
    firstChatMessageId: null,
    globalDataLoaded: false,
    globalData: {},
    information: {},
    lastChatMessageId: null,
    missingHeadshot: 'https://www.wosfl.com/images/missing_headshot.png',
    modalOverflowAdd: false,
    nflTeamsData: [],
    notifications: {},
    now: new Date(),
    nowIntervalSet: false,
    playerInfoModal: false,
    playerInfoModalPlayer: {},
    playerSearchInput: '',
    playerSearchLoading: false,
    playerSearchResults: [],
    playerSearchResultsDisplayLimit: 15,
    playersWatching: [],
    positionsDfs: [
      'qb',
      'rb1',
      'rb2',
      'wr1',
      'wr2',
      'wr3',
      'te',
      'flex',
      'dst',
    ],
    positionsBench: [
      'bench1',
      'bench2',
      'bench3',
      'bench4',
      'bench5',
      'bench6',
      'bench7',
      'bench8',
    ], //TODO: make these caps ?
    positionsPlayers: ['QB', 'RB', 'WR', 'TE', 'K', 'DST'],
    positionsStarters: [
      'qb',
      'rb1',
      'rb2',
      'wr1',
      'wr2',
      'wr3',
      'te',
      'flex',
      'k',
      'dst',
    ], //TODO: make these caps?
    scrollTopBtn: false,
    sideNav: false,
    sideNavMenuItems: {},
    snackbarSettings: {
      defaultColor: 'primary',
      'multi-line': true,
      bottom: true,
      right: true,
      state: false,
      timeout: 5000,
      vertical: true,
    },
    snackbarState: false,
    snackbar: false,
    snackbarData: {},
    snackbarColor: null,
    snackbarIcon: null,
    snackbarMessage: null,
    snackbarStatus: null,
    status: null,
    statusMessage: null,
    teamInfoModal: false,
    teamInfoModalTeam: {},
    ticker: [],
    toolbarTabs: {
      active: 0,
      items: {},
      visible: false,
    },
    usersData: {},
    vMainEl: null,
    weekData: {},
    weekScores: {},
  },
  getters: {
    chatMessageTotal(state) {
      let total = 0
      Object.values(state.chatMessageCounts).forEach((c) => (total += +c))
      return total
    },
    navCounts(state) {
      let counts = {}
      if (!state.counts) {
        return counts
      }
      counts.auctions = {}
      counts.frontoffice = {}
      counts.trades = {}
      counts.auctions.total = state.counts.auctions.total || 0
      counts.trades.total = state.counts.auctions.total || 0

      counts.frontoffice.total = counts.auctions.total + counts.trades.total
      return counts
    },
    nflTeamsById(state) {
      return (
        state.nflTeamsData?.reduce((acc, curr) => {
          return { ...acc, [curr.id]: curr }
        }, {}) || {}
      )
    },
    positions(state) {
      return [...state.positionsStarters, ...state.positionsBench]
      //this is the the spread operator.
      //it "spreads" the values of a given array or object.
      //the above will return a new array with values of the above two
      //arrays
    },
    positionsBench(state) {
      let benchPositions = []
      if (state.globalData.positions) {
        state.globalData.positions.forEach((position) => {
          if (position.substring(0, 5) === 'bench') {
            benchPositions.push(position)
          }
        })
      }
      return benchPositions
    },
    positionsStarters(state) {
      let startingPositions = []
      if (state.globalData.positions) {
        state.globalData.positions.forEach((position) => {
          if (position.substring(0, 5) !== 'bench') {
            startingPositions.push(position)
          }
        })
      }
      return startingPositions
    },
    ticker(state) {
      if (state.ticker) {
        let ticker = ''
        for (let item in state.ticker) {
          ticker = ticker + state.ticker[item]['text'] + ' • '
        }
        if (ticker.slice(-3) === ' • ') {
          ticker = ticker.slice(0, -3)
        }
        return ticker.toUpperCase()
      } else {
        return `WOSFL ${new Date().getFullYear()}`
      }
    },
  },

  actions: {
    /*
     *
     * KEEPING NEW ACTIONS AT TOP DURING REFACTOR
     *
     * */
    apiCall(context, data) {
      //apiCall(uri, postData, urlMod = 'default')
      // console.log({ ...data })
      const { uri, postData, urlMod } = data
      return axiosMixins.callApi(uri, postData, urlMod)
    },

    chatDataUpdate({ commit, dispatch, getters, state, rootState }, data) {
      const { messages, newMessages } = data
      if (Array.isArray(messages) && messages.length) {
        //in case of race condition between syncdata and new chat sent...
        //ensure no duplicate messages
        messages.forEach((m) => {
          const idx = state.chatMessages.findIndex((i) => i.id === m.id)
          if (idx < 0) {
            commit('SET_STATE_ITEM', {
              item: 'chatMessages',
              value: [...state.chatMessages, m],
            })
          }
        })
      }

      //Set message count for each channel
      //All will always be incremented (b.c syncdata will keep session lastid current)
      for (let channel in newMessages) {
        const currentCount =
          channel !== 'All' ||
          typeof state.chatMessageCounts[channel] === 'undefined'
            ? 0
            : parseInt(state.chatMessageCounts[channel])
        const newCount =
          state.chatVisible && state.chatChannel === channel
            ? 0
            : currentCount + parseInt(newMessages[channel])
        commit('SET_STATE_ITEM_KEY', {
          item: 'chatMessageCounts',
          key: channel,
          value: newCount,
        })
      }

      //if chat window open on a channel, clear message count
      if (state.chatVisible) {
        commit('SET_STATE_ITEM_KEY', {
          item: 'chatMessageCounts',
          key: state.chatChannel,
          value: 0,
        })
      }

      //Show snackbar
      if (getters.chatMessageTotal > 0 && data?.messages?.length) {
        let sbMessage = ''
        const nonUserMessages = messages
          .filter(
            (m) => m.userid !== rootState?.authenticationStore?.userData?.id,
          )
          .sort((a, b) => b.id - a.id)
        const sbMsgCount = state.chatVisible
          ? nonUserMessages.filter((m) => m.channel !== state.chatChannel)
              .length
          : getters.chatMessageTotal
        if (sbMsgCount < 1) return
        if (sbMsgCount > 1) {
          sbMessage = sbMsgCount + ' new chat messages'
        } else {
          sbMessage =
            messages['0']['msgtype'] === 'text'
              ? `<strong>${state.usersData[nonUserMessages['0']['userid']].id}
                  said: </strong> ${nonUserMessages['0']['msgcontent']}`
              : state.usersData[nonUserMessages['0']['userid']].id +
                ' posted an image in chat'
        }
        dispatch('snackbarToggle', {
          sbState: true,
          sbData: {
            status: 'info',
            message: sbMessage,
            visible: true,
          },
        })
        const notification = new Notification('Chat', {
          body: sbMessage,
          icon: '/img/icons/favicon-16x16.png',
        })
        console.log(notification)
      }
    },
    setStateItem({ commit }, data) {
      //expects item: stateitem to update, value: new state value
      commit('SET_STATE_ITEM', data)
    },
    setStateItemKey({ commit }, data) {
      //expects item:stateitem , key:key of stateitem,  value: new value of item[key]
      commit('SET_STATE_ITEM_KEY', data)
    },
    snackbarToggle({ commit, state }, { sbState, sbData }) {
      commit('SET_STATE_ITEM', { item: 'snackbarData', value: sbData })
      commit('SET_STATE_ITEM', {
        item: 'snackbarState',
        value: sbState || !state.snackbarState,
      })
    },
    toggleStateItem({ commit }, item) {
      // console.log(item)
      commit('TOGGLE_STATE_ITEM', item)
    },

    /* *
     *
     * OLD BELOW HERE, REFACTOR ALLLLL
     * OR MOVE ABOVE THIS LINE
     *
     * */

    ///MOVED FROM COMMISH
    ///TODO: MOVE TO COMPONENT
    playerSearchPlayers({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        // pass search input to endpoint
        let uri = 'players_search/' + state.playerSearchInput
        if (data && data.position !== undefined) {
          uri = uri + '/' + data.position
        }
        dispatch('apiCall', { uri }).then(
          async (res) => {
            if (res) {
              const limit = res.length < 15 ? res.length : 15
              commit('playerSetSearchResultsDisplayLimit', limit)
              commit('playerSetSearchResults', res)
              commit('playerSetSearching', false)
            }
            resolve(res) // Let the calling function know that http is done. You may send some data back
          },
          (error) => {
            // http failed, let the calling function know that action did not work out
            reject(error)
          },
        )
      })
    },
    playerSearchIncrementLimit({ commit, state }) {
      const limit =
        state.playerSearchResultsDisplayLimit + 15 >=
        state.playerSearchResults.length
          ? state.playerSearchResults.length
          : state.playerSearchResultsDisplayLimit + 15
      commit('playerSetSearchResultsDisplayLimit', limit)
    },

    //
    //
    //

    alertAcknowledge(context, data) {
      //data expects id of alert
      // console.log(data)
      return new Promise((resolve, reject) => {
        let uri = `message_acknowledge/${data}`
        axiosMixins.callApi(uri).then(
          (response) => {
            let alertKey = context.state.alerts.findIndex(
              (alert) => alert.info_id === data,
            )
            if (alertKey > -1) {
              context.commit('alertRemove', alertKey)
            } else {
              console.log('alert key not found')
            }
            resolve(response)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    // alertRemove({ commit, state }, data) {
    //   let alertKey = state.alerts[data.type].findIndex(
    //     alert => alert.id === data
    //   )
    //   if (alertKey > -1) {
    //     commit('alertRemove', { type: data.type, key: alertKey })
    //   }
    // },
    calculateDimensions(context) {
      let dimensions = {}
      let windowSize = {}
      let contentContainerHeight = 0
      windowSize = { x: window.innerWidth, y: window.innerHeight }
      let toolbar = 50
      let toolbarTabs = 0
      if (context.state.toolbarTabs['visible']) {
        toolbarTabs = 50
      } else {
      }
      let footer = 50
      let bottomNav = 60
      let testMods = 10
      let adjustment = toolbar + footer + bottomNav + toolbarTabs + testMods
      dimensions['contentContainerHeight'] = windowSize.y - adjustment
      context.commit('setDimensions', dimensions)
    },

    initializeData({ dispatch, commit, rootState }, route) {
      return new Promise((resolve, reject) => {
        let uri = 'global_get_initial_data'
        if (route) {
          uri += `/${route}`
        }
        // console.log(uri)
        axiosMixins.callApi(uri).then(
          (response) => {
            if (response && response !== 'noAuth') {
              let globalData = {}
              let lineupData = {}
              //TODO CLEAN THIS SHIT UP
              console.log(response)
              globalData.globalData = response.globalData || null
              globalData.chatData =
                response.syncData && response.syncData.chat
                  ? response.syncData.chat
                  : null
              // console.log(globalData.chatData)
              globalData.playersWatching = response.playersWatching
              globalData.userData = response.userData || null
              // globalData.globalData.weekData['pickems_end'] = '2018-08-22 19:25:40'
              if (response && response.lineupData) {
                lineupData.lineup = response.lineupData.lineup || null
                lineupData.playerData = response.lineupData.playerData || null
              }

              commit('initializeData', globalData)

              if (response.syncData) {
                //commit these
                const syncData = response.syncData
                for (let item in syncData) {
                  // console.log(item)
                  // console.log(response[item])
                  if (item === 'chat') {
                    dispatch('chatDataUpdate', syncData[item])
                  } else {
                    commit('setSyncData', {
                      item: item,
                      data: syncData[item],
                    })
                  }
                }
              }

              // dispatch('updateNow')
            }
            // console.log('resolve response')
            resolve(response)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    notificationAcknowledge(context, data) {
      return new Promise((resolve, reject) => {
        let uri = `message_acknowledge/${data.id}`
        axiosMixins.callApi(uri).then(
          (response) => {
            let notificationKey = context.state.notifications[
              data.type
            ].findIndex((notification) => notification.id === data.id)
            if (notificationKey > -1) {
              context.commit('notificationRemove', {
                type: data.type,
                key: notificationKey,
              })
            } else {
              console.log('notification key not found')
            }
            resolve(response)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },

    notificationRemove({ commit, state }, data) {
      let notificationKey = state.notifications[data.type].findIndex(
        (notification) => notification.id === data.id,
      )
      if (notificationKey > -1) {
        commit('notificationRemove', { type: data.type, key: notificationKey })
      }
    },
    notificationSet({ commit }, data) {
      // console.log(data)
      const acceptedNotifications = ['commish', 'rosterOverflow', 'tradeVote']
      if (acceptedNotifications.indexOf(data.notificationType) > -1) {
        commit('notificationSet', {
          type: data.notificationType,
          data: data.data,
        })
      }
    },
    playerModalUpdateHeadshot({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        // pass search input to endpoint
        let uri = 'player_update_headshot/' + data.player.id
        axiosMixins.callApi(uri, null, 'commish').then(
          async (response) => {
            if (response.data && response.data.headshot) {
              commit('togglePlayerModal', {
                state: 'visible',
                player: {
                  ...state.playerInfoModalPlayer,
                  headshot: response.data.headshot,
                },
              })
            }
            dispatch('snackbarToggle', {
              sbState: true,
              sbData: { status: response.status, message: response.message },
            })
            resolve(response) // Let the calling function know that http is done. You may send some data back
          },
          (error) => {
            // http failed, let the calling function know that action did not work out
            reject(error)
          },
        )
      })
    },

    setFilterDropDown1(context, value) {
      context.commit('filterSetDropDown1', value)
    },
    setFilterDropDown2(context, value) {
      context.commit('filterSetDropDown2', value)
    },
    filterSearchInput(context, value) {
      context.commit('filterSetSearchInput', value)
    },
    // setGamedayGameTypeViewing({ commit }, value) {
    //   commit('gamedayGameTypeViewing', value)
    // },
    setPlayerTileActionBtnLoading(context, playerid) {
      context.commit('playerTileActionBtnLoading', playerid)
    },
    setTicker({ commit }, data) {
      // console.log(data)
      commit('setTicker', data)
    },
    setToolbarTabValues(context, data) {
      context.commit('setToolbarTabValues', data)
    },
    setToolbarTabActive(context, activeTab) {
      context.commit('setToolbarTabActive', activeTab)
    },
    syncData({ commit, dispatch, state }, data) {
      // console.log('run sync data')
      return new Promise((resolve, reject) => {
        let uri = data ? `syncData/${data}` : `syncData`
        axiosMixins.callApi(uri).then(
          (response) => {
            // console.log(response)
            if (response) {
              // console.log(response)
              //commit these
              for (let item in response) {
                // console.log(item)
                // console.log(response[item])
                if (item === 'chat' && response[item]) {
                  dispatch('chatDataUpdate', response[item])
                } else {
                  commit('setSyncData', { item: item, data: response[item] })
                }
                // setGamedayGameTypeViewing({ commit }, value) {
                //   commit('gamedayGameTypeViewing', value)
                // },   }
              }
              resolve(response)
            }
          },
          (error) => {
            console.log('error getting sync data')
            reject(error)
          },
        )
      })
    },
    toggleChat({ commit }, data) {
      commit('toggleChat')
    },
    toggleContentDivMaxScrollReached(context, value) {
      context.commit('toggleContentDivMaxScrollReached', value)
    },
    toggleFilter(context, value) {
      context.commit('toggleFilter', value)
    },
    toggleFilterBtn(context, value) {
      context.commit('toggleFilterBtn', value)
    },
    toggleFilterBtnLoading(context, value) {
      context.commit('toggleFilterBtnLoading', value)
    },
    togglePageDataLoading(context, value) {
      context.commit('togglePageDataLoading', value)
    },
    togglePlayerModal(context, value) {
      if (value.player.id === 'TBD') {
        // if (value.player.id === 'TBD' || value?.player?.id === 'UNK') {
        context.commit('togglePlayerModal', {
          state: value.state,
          player: {
            first_name: 'Donal',
            fixedname: 'Donal',
            full_name: 'Donal',
            gameData: {
              opponent: 'Pls',
            },
            headshot: 'https://www.wosfl.com/images/missing_headshot.png',
            id: '3835',
            injuryinfo: null,
            last_name: '',
            lineup_position: value.player.lineup_position,
            name_abv: 'D',
            owner: 'Gooby',
            id: 'TBD',
            position: null,
            salary: '0',
            team: 'UNK',
            teamIcon:
              'https://www.wosfl.com/images/icons/wosfl_logo_trans_162x192.png',
          },
        })
      } else {
        context.commit('togglePlayerModal', value)
      }
    },
    toggleScrollTopBtn(context, value) {
      context.commit('toggleScrollTopBtn', value)
    },
    toggleSideNav(context) {
      context.commit('toggleSideNav')
    },
    toggleSnackbar(context, data) {
      const defaultSnackbar = {
        icon: null,
        message: null,
        snackbarColor: null,
        status: null,
        visible: false,
      }
      if (data.data) {
        context.commit('toggleSnackbar', data.data)
      } else {
        // context.commit('toggleSnackbar', {})
        context.commit('toggleSnackbar', data)
      }
      setTimeout(() => {
        context.commit('toggleSnackbar', defaultSnackbar)
      }, 5000)
    },
    toggleTeamModal(context, value) {
      //TODO: FINISH IF OPENING TEAM MODALWHILE OPEN, CLOSE AND REOPEN
      // console.log(value)
      // console.log(context.state.teamInfoModal)
      // if (value?.state === true && context.state.teamInfoModal) {
      //   console.log('close')
      //   context.commit('toggleTeamModal', { state: false, team: {} })
      // }
      context.commit('toggleTeamModal', value)
    },
    // updateChatMessages({ commit, state }, data) {},
    updateNow(context) {
      context.commit('updateNow')
    },
    usersDataSetData({ commit }, data) {
      commit('usersDataSetData', data)
    },
    watchPlayer({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        let uri = `player_watching/${data.action}/${data.player}`
        dispatch('apiCall', { uri }).then(
          (res) => {
            dispatch('snackbarToggle', {
              sbState: true,
              sbData: { status: res.status, message: res.message },
            })
            if (res.status === 'success') {
              if (data.action === 'ADD') {
                dispatch('setStateItem', {
                  item: 'playersWatching',
                  value: [...state.playersWatching, data.player],
                })
              }
              if (data.action === 'REMOVE') {
                // console.log('is remove')
                // console.log(state.playersWatching)
                // console.log(data.player)
                dispatch('setStateItem', {
                  item: 'playersWatching',
                  value: [...state.playersWatching].filter(
                    (p) => p !== data.player,
                  ),
                })
              }
            }
            resolve(res)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
  },
  mutations: {
    /*
     *
     * KEEPING NEW MUTATIONS AT TOP DURING REFACTOR
     *
     * */
    SET_STATE_ITEM(state, { item, value }) {
      state[item] = value
    },
    SET_STATE_ITEM_KEY(state, { item, key, value }) {
      const newData = { ...state[item] }
      newData[key] = value
      state[item] = { ...newData }
    },
    TOGGLE_STATE_ITEM(state, item) {
      state[item] = !state[item]
    },

    /*
     *
     * TODO: REFACTOR ALL BELOWWWW
     *
     * */

    alertRemove(state, data) {
      Vue.delete(state.alerts, data)
    },
    //TODO: REMOVE THIS!!!!
    //COMMIT SETITEM FROM ACTION
    initializeData(state, data) {
      console.log(data)
      state.appVersion = data.appVersion
      state.globalData = data.globalData
      state.divisions = data.globalData.divisions
      state.nflTeamsData = data.globalData.nflTeamsData
      state.playersWatching = data.playersWatching
      state.positions = data.globalData.positions
      state.sideNavMenuItems = data.globalData.menuItems
      state.ticker = data.globalData.ticker
      state.usersData = data.globalData.teamsData
      state.weekData = data.globalData.weekData
      // globalData.globalData.weekData['pickems_end'] = '2018-08-22 19:25:40'
      state.globalDataLoaded = true
    },
    setDimensions(state, dimensions) {
      for (let i in dimensions) {
        state.dimensions[i] = dimensions[i] + 'px'
      }
      state.dimensionsContentHeight = state.dimensions['contentContainerHeight']
    },
    setTicker(state, data) {
      state.ticker = data
    },
    filterSetCheckbox1(state, value) {
      Vue.set(state, 'filterCheckbox1', value)
    },
    filterSetDropDown1(state, value) {
      Vue.set(state, 'filterDropdown1', value)
    },
    filterSetDropDown2(state, value) {
      Vue.set(state, 'filterDropdown2', value)
    },
    filterSetDropDown3(state, value) {
      Vue.set(state, 'filterDropdown3', value)
    },
    filterSetSelectedTeam(state, team) {
      state.filterSelectedTeam = team
    },
    filterSetSearchInput(state, value) {
      Vue.set(state, 'filterSearchInput', value)
    },
    // gamedayGameTypeViewing(state, value) {
    //   state.gamedayGameTypeViewing = value
    // },
    messagesRemoveMessage(state, data) {
      Vue.delete
    },
    notificationRemove(state, data) {
      Vue.delete(state.notifications[data.type], data.key)
    },
    notificationSet(state, data) {
      console.log(data)
      Vue.set(state.notifications, data.type, data.data)
    },
    playerSetSearchInput(state, data) {
      state.playerSearchInput = data
    },
    playerSetSearchResults(state, data) {
      state.playerSearchResults = data
    },
    playerSetSearchResultsDisplayLimit(state, data) {
      state.playerSearchResultsDisplayLimit = data
    },
    playerSetSearching(state, data) {
      state.playerSearchLoading = data
    },
    playerTileActionBtnLoading(state, playerid) {
      Vue.set(state, 'playerTileActionBtnLoading', playerid)
    },
    setSyncData(state, data) {
      //data expects:
      // item: count, alerts, notifications, etc
      // data: value of data
      Vue.set(state, data.item, data.data)
    },
    setToolbarTabActive(state, activeTab) {
      Vue.set(state, 'toolbarTabActive', activeTab)
    },
    setToolbarTabValues(state, data) {
      if (data['visible'] !== undefined) {
        state.toolbarTabs['visible'] = data['visible']
      }
      if (data['items'] !== undefined) {
        state.toolbarTabs['items'] = data['items']
      }
      this.dispatch('calculateDimensions')
    },
    toggleChat(state, data) {
      state.chatVisible = data !== undefined ? data : !state.chatVisible
    },
    toggleContentDivMaxScrollReached(state, value) {
      // console.log(value)
      state.contentDivMaxScrollReached = value
    },
    toggleFilter(state, value) {
      if (value !== undefined) {
        state.filter = value
      } else {
        state.filter = !state.filter
      }
    },
    toggleFilterBtn(state, value) {
      if (value !== undefined) {
        state.filterBtn = value
      } else {
        state.filterBtn = !state.filterBtn
      }
    },
    toggleFilterBtnLoading(state, value) {
      if (value !== undefined) {
        state.filterBtnLoading = value
      } else {
        state.filterBtnLoading = !state.filterBtnLoading
      }
    },
    togglePageDataLoading(state, value) {
      if (value !== undefined) {
        state.pageDataLoading = value
      } else {
        state.pageDataLoading = !state.pageDataLoading
      }
    },
    togglePlayerModal(state, value) {
      state.playerInfoModal = value.state
      state.playerInfoModalPlayer = value.player
    },
    toggleScrollTopBtn(state, value) {
      if (value !== undefined) {
        state.scrollTopBtn = value
      } else {
        state.scrollTopBtn = !state.scrollTopBtn
      }
    },
    toggleSideNav(state) {
      state.sideNav = !state.sideNav
    },
    toggleSnackbar(state, data) {
      if (data) {
        if (data.status) {
          state.snackbarColor = data.status
        }
        if (data.message) {
          state.snackbarMessage = data.message
        }
        if (data.icon) {
          state.snackbarIcon = data.icon
        } else {
          //SET THIS TO NULL SO DEFAULTS ARE USED IN SB COMP
          state.snackbarIcon = null
        }
        if (data.visible !== null && data.visible !== undefined) {
          state.snackbar = data.visible
        } else {
          state.snackbar = !state.snackbar
        }
      } else {
        state.snackbar = !state.snackbar
      }
    },

    toggleTeamModal(state, value) {
      state.teamInfoModal = value.state
      state.teamInfoModalTeam = value.team
    },
    usersDataSetData(state, data) {
      Vue.set(state, 'usersData', data)
    },
  },
}
