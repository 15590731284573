import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// import axiosConfig from './Configs/axiosConfig'
import VueAxios from 'vue-axios'
import Vue2Filters from 'vue2-filters'

import VAnimateCss from 'v-animate-css'

Vue.config.productionTip = true
// Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(Vue2Filters)
Vue.use(VAnimateCss)

//GLOBAL COMPONENTS
import PageLoader from '@/components/Global/Pageloader'
Vue.component('PageLoader', PageLoader)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
