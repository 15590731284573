var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-main', [_c('v-app-bar', {
    ref: "topToolbar",
    staticClass: "justify-space-between align-center font-weight-black secondary--text",
    attrs: {
      "app": "",
      "color": _vm.$vuetify.theme.themes.light.primary,
      "clipped-right": ""
    }
  }), _c('v-img', {
    staticClass: "logo",
    attrs: {
      "contain": "",
      "width": "384",
      "src": "https://www.wosfl.com/images/wosfl_logo.svg"
    }
  }), _c('div', {
    staticClass: "login d-flex flex-column justify-center align-center align-content-center"
  }, [_c('placeHolder', {
    attrs: {
      "text": _vm.siteStatusMessage,
      "height": "10"
    }
  })], 1), _c('v-footer', {
    attrs: {
      "app": "",
      "height": "56",
      "color": _vm.$vuetify.theme.themes.light.primary
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }