<template>
  <v-card flat color="transparent" :height="height ? height : dimensionsContentHeight ? parseInt(dimensionsContentHeight.substr(0, 3)) - 20 + 'px' : '100%'" class="text-center">
    <v-container class="text-center">
      <v-row align="center">
        <v-col>
          <v-card flat color="transparent">
            <v-card-text>
              <p class="blockquote align-center font-weight-bold primary--text">
                <v-icon v-if="!loading && icon" x-large color="primary" class="pb-6">{{ icon ? icon : 'mdi mdi-exclamation-triangle' }}</v-icon>

                <v-avatar v-if="!loading && avatar" size="75" class="pb-6"> <img :src="`https://wosfl.com/boards/download/file.php?avatar=${avatar}`"/></v-avatar>
                <v-img v-if="loading" height="50" contain src="https://www.wosfl.com/images/loading_spin.gif"></v-img>
                <br />
                {{ text }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: 'PlaceholderCard',
    props: ['icon', 'height', 'loading', 'text', 'link', 'avatar'],
    computed: {
      ...mapState({
        contentDivMaxScrollReached: state => state.mainStore.contentDivMaxScrollReached,
        dimensionsContentHeight: state => state.mainStore.dimensionsContentHeight,
      }),
    },
  }
</script>
