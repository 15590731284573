import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Home',
      path: '/home',
      meta: { baseView: 'home' },
      component: () => import(/* webpackChunkName: "home" */ '@/components/Home/Home.vue')
    },
    {
      name: 'Articles',
      path: '/articles/:id?',
      meta: { baseView: 'articles' },
      props: true,
      component: () => import(/* webpackChunkName: "home" */ '@/components/Home/Articles.vue')
    },
    {
      name: 'Auctions',
      path: '/auctions/:view?',
      meta: { baseView: 'auctions' },
      component: () => import(/* webpackChunkName: "auctions" */ '@/components/Auction/Auctionhouse.vue')
    },
    {
      name: 'Dfs',
      path: '/dfs/:view?',
      meta: { baseView: 'dfs' },
      component: () => import(/* webpackChunkName: "dfs" */ '@/components/Dfs/Dfs.vue')
    },
    {
      name: 'Finances',
      path: '/finances',
      meta: { baseView: 'finances' },
      component: () => import(/* webpackChunkName: "league" */ '@/components/League/Finances.vue')
    },
    {
      name: 'Team',
      path: '/team/:view?',
      meta: { baseView: 'team' },
      component: () => import(/* webpackChunkName: "team" */ '@/components/Frontoffice/Lineup.vue')
    },
    {
      name: 'Login',
      path: '/login/:view?',
      meta: { baseView: 'login', public: true },
      component: () => import(/* webpackChunkName: "login" */ '@/components/Login/Login.vue')
    },
    {
      name: 'Messageboards',
      path: '/boards',
      meta: { baseView: 'boards' },
      component: () => import(/* webpackChunkName: "boards" */ '@/components/Messageboards/Messageboards.vue')
    },
    {
      name: 'Players',
      path: '/players/:view?',
      meta: { baseView: 'players' },
      component: () => import(/* webpackChunkName: "players" */ '@/components/Player/Players.vue')
    },
    {
      name: 'Scoreboard',
      path: '/scoreboard/:view?/:option?',
      meta: { baseView: 'scoreboard' },
      beforeEnter: (to, from, next) => {
        if (!Store.state.scoreboardStore) {
          import('@/store/scoreboard')
            .then(({ scoreboardStore }) => {
              // console.log("register module scoreboardStore from router guard");
              Store.registerModule('scoreboardStore', scoreboardStore)
            })
            .then(() => {
              next()
            })
        } else {
          next()
        }
      },
      component: () => import(/* webpackChunkName: "scoreboard" */ '@/components/Scoreboard/Scoreboard.vue')
    },
    {
      name: 'Standings',
      path: '/standings/:view?',
      meta: { baseView: 'standings' },
      component: () => import(/* webpackChunkName: "league" */ '@/components/League/Standings.vue')
    },
    {
      meta: { baseView: 'trades' },
      name: 'Trades',
      path: '/trades/:view?/:team?',
      component: () => import(/* webpackChunkName: "trades" */ '@/components/Trade/Trades.vue')
    },

    // {
    //   path: '/frontoffice',
    //   meta: { baseView: 'frontoffice' },
    //   beforeEnter: (to, from, next) => {
    //     if (!Store.state.frontofficeStore) {
    //       import('@/store/frontoffice')
    //         .then(({ frontofficeStore }) => {
    //           // console.log("register module frontofficeStore from router guard");
    //           Store.registerModule('frontofficeStore', frontofficeStore)
    //         })
    //         .then(() => {
    //           next()
    //         })
    //     } else {
    //       next()
    //     }
    //   },
    //   component: () => import(/* webpackChunkName: "frontoffice" */ '@/components/Frontoffice/Frontoffice.vue'),
    //   children: [
    //     {
    //       name: 'Tradecenter',
    //       path: 'tradecenter/:view?',
    //       meta: { baseView: 'frontoffice' },
    //       component: () => import(/* webpackChunkName: "frontoffice" */ '@/components/Frontoffice/Tradecenter.vue'),
    //     },

    //     {
    //       //DEFAULT ROUTE
    //       path: '/',
    //       redirect: 'lineup/starters',
    //     },
    //   ],
    // },
    {
      path: '/league/:view?',
      name: 'League',
      meta: { baseView: 'league' },
      component: () => import(/* webpackChunkName: "league" */ '@/components/League/League.vue')
    },
    {
      name: 'Sidepots',
      path: '/sidepots/:view?',
      meta: { baseView: 'sidepots' },
      component: () => import(/* webpackChunkName: "frontoffice" */ '@/components/Sidepots/Sidepots.vue')
    },
    // {
    //   path: '/sidepots',
    //   // redirect: { name: 'Pickems', params: { view: 'picks' } },
    //   meta: { baseView: 'sidepots' },
    //   component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Sidepots.vue'),
    //   children: [
    //     {
    //       name: 'Bestball',
    //       path: 'bestball/:view?',
    //       meta: { baseView: 'sidepots' },
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Bestball.vue'),
    //     },
    //     {
    //       name: 'Dfs',
    //       path: 'dfs/:view?',
    //       meta: { baseView: 'sidepots' },
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Dfs.vue'),
    //     },
    //     {
    //       name: 'Pickems',
    //       path: 'pickems/:view?',
    //       meta: { baseView: 'sidepots' },
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Pickems.vue'),
    //     },
    //     {
    //       name: 'Survivor',
    //       path: 'survivor/:view?',
    //       meta: { baseView: 'sidepots' },
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Survivor.vue'),
    //     },
    //     {
    //       name: 'Bets',
    //       path: 'bets/:view?',
    //       meta: { baseView: 'sidepots' },
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Sidepots.vue'),
    //     },
    //     {
    //       //DEFAULT ROUTE
    //       path: '/',
    //       // redirect: 'pickems/picks',
    //       component: () => import(/* webpackChunkName: "sidepots" */ '@/components/Sidepots/Sidepots.vue'),
    //     },
    //   ],
    // },
    {
      path: '/user',
      // redirect: { name: 'Pickems', params: { view: 'picks' } },
      meta: { baseView: 'user' },

      component: () => import(/* webpackChunkName: "user" */ '@/components/User/User.vue'),
      children: [
        {
          name: 'Profile',
          path: 'profile/:view?',
          meta: { baseView: 'user/profile' },
          component: () => import(/* webpackChunkName: "user" */ '@/components/User/User_Profile.vue')
        },
        {
          name: 'Settings',
          path: 'settings/:view?',
          meta: { baseView: 'user/settings' },
          component: () => import(/* webpackChunkName: "user" */ '@/components/User/User_settings.vue')
        },
        {
          //DEFAULT ROUTE
          path: '/',
          redirect: 'Settings/profile'
        }
      ]
    },
    {
      name: 'Commish',
      path: '/commish',
      meta: { baseView: 'commish' },
      beforeEnter: (to, from, next) => {
        if (!Store.state.commishStore) {
          import('@/store/commish')
            .then(({ commishStore }) => {
              Store.registerModule('commishStore', commishStore)
            })
            .then(() => {
              next()
            })
        } else {
          next()
        }
      },
      component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish.vue'),

      children: [
        {
          name: 'FinancesMGMT',
          path: 'financesmgmt/:view?',
          meta: { baseView: 'commish' },
          component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish_Finances.vue')
        },
        {
          name: 'LeagueMGMT',
          path: 'leaguemgmt/:view?',
          meta: { baseView: 'commish' },
          component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish_League.vue')
        },
        {
          name: 'MessagesMGMT',
          path: 'messagesmgmt/:view?',
          meta: { baseView: 'commish' },
          component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish_Messages.vue')
        },
        {
          name: 'PlayersMGMT',
          path: 'playersmgmt/:view?',
          meta: { baseView: 'commish' },
          component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish_Players.vue')
        },
        {
          name: 'TransactionsMGMT',
          path: 'transactionsmgmt/:view?',
          meta: { baseView: 'commish' },
          component: () => import(/* webpackChunkName: "commish" */ '@/components/Commish/Commish_Transactions.vue')
        },
        {
          //DEFAULT ROUTE
          path: '/',
          redirect: 'messages/ticker'
        }
      ]
    },
    {
      name: 'Admin',
      path: '/AdminNineteen/:view?',
      meta: { baseView: 'admin' },
      // beforeEnter: (to, from, next) => {
      //   if (!Store.state.commishStore) {
      //     import('@/store/commish')
      //       .then(({ commishStore }) => {
      //         Store.registerModule('commishStore', commishStore)
      //       })
      //       .then(() => {
      //         next()
      //       })
      //   } else {
      //     next()
      //   }
      // },
      component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Admin.vue')
    },
    {
      //DEFAULT ROUTE, NO MATCHING ROUTE FOUND
      //CREATE 404 COMP
      // name: 'Unfound',
      path: '/',
      redirect: '/Home'
      // meta: { baseView: 'unfound' }
    }
  ]
})
router.beforeEach((to, from, next) => {
  // ...
  if (!Store.getters.isAuth) {
    if (Store.getters.firstLoad) {
      Store.dispatch('testAuth')
        .then(response => {
          // console.log(response)
          Store.dispatch('firstLoad')
          if (response.data === 'unfound19' || response.data.error === 'unfound19') {
            // console.log(to)
            to.name === 'Login' ? next() : next({ name: 'Login' })
          } else {
            to.name === 'Login' ? next({ name: 'Home' }) : next()
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
