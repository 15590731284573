<script>
  import { mapActions, mapState } from 'vuex'
  //import { setTimeout } from 'timers'
  export default {
    name: 'LoginCard',
    components: {},
    data() {
      return {
        authCode: null,
        authcodeSendLoading: false,
        authCodeSuccess: false,
        authCodeTimeout: null,
        errorModal: false,
        errorText: null,
        isPasswordReset: false,
        isRequestCode: false,
        loading: false,
        login: null,
        loginFailed: false,
        password: null,
        passwordConf: null,
        rememberMe: false,
        sessionExpired: false,
        username1: null,
      }
    },
    computed: {
      ...mapState({
        isAuth: state => state.authenticationStore.isAuth,
        userData: state => state.authenticationStore.userData,
      }),
      authcodeText() {
        if (this.authCodeTimeout !== null) {
          return {
            class: 'error--text',
            text: 'Authorization code sent. Check email or retry in ' + this.authCodeTimeout + ' minutes.',
          }
        }
        if (this.errorText) {
          return {
            class: 'error--text',
            text: this.errorText,
          }
        }
        if (this.authCodeSuccess) {
          return {
            class: 'success--text',
            text: 'An authorization code has been sent to the email on file for the requested account, should it exist.',
          }
        }

        return {
          class: 'primary--text font-weight-bold',
          text: "Don't have an Authorization Code?",
        }
      },

      disabled() {
        if (this.isPasswordReset) {
          if (this.authCode === null || this.password === null || this.passwordConf === null) {
            return true
          }
          if (this.password !== this.passwordConf) {
            return true
          }
          return false
        }
        return this.login === null || this.password === null
      },
    },
    methods: {
      authcodeRequestBtn() {
        this.isRequestCode = !this.isRequestCode
        if (!this.isRequestCode) {
          this.username1 = null
          this.authcodeSendLoading = false
        }
      },
      resetPasswordBtn() {
        this.isPasswordReset = !this.isPasswordReset
        if (!this.isPasswordReset) {
          this.authCodeSuccess = false
          this.authCodeTimeout = null
          this.isRequestCode = false
          this.username1 = null
        }
      },
      testDestroy() {
        this.$store.dispatch('destroyApp')
      },
      testAuth() {
        this.$store.dispatch('testAuth').then(response => {
          console.log(response)
        })
      },
      loginUser() {
        if ((this.login && this.password) || (this.isPasswordReset && !this.disabled)) {
          this.loading = true
          this.$store
            .dispatch('loginUser', {
              login: this.isPasswordReset ? null : this.login,
              authcode: this.isPasswordReset ? this.authCode : null,
              password: this.password,
              rememberMe: this.rememberMe,
            })
            .then(response => {
              console.log(response)
              if (response.data.status === 'success') {
                if (this.rememberMe) {
                  localStorage.setItem('rememberLogin', true)
                  localStorage.setItem('username', this.login)
                } else {
                  localStorage.removeItem('rememberLogin')
                  localStorage.removeItem('username')
                }
                this.$router.push({ name: 'Home' })
              }
              if (response.data.status === 'error') {
                if (response.data.message === 'update') {
                  this.errorModal = true
                } else {
                  this.loginFailed = true
                  this.errorText = response.data.message
                }
              }
              this.loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      logoutUser() {
        this.$store.dispatch('logoutUser')
      },
      submitAuthcodeRequest() {
        if (this.username1 !== null && this.username1.length > 2) {
          this.authcodeSendLoading = true
          console.log('submitAuthcodeRequest')
          console.log(this.username1)
          this.$store.dispatch('requestAuthcode', this.username1).then(resp => {
            console.log(resp)
            console.log(resp.message)
            if (resp.status === 'error') {
              if (resp.message === 'timeout') {
                this.authCodeTimeout = resp.data
                this.isRequestCode = false
              }
            }
            if (resp.status === 'success') {
              this.authCodeTimeout = null
              this.authCodeSuccess = true
              this.isRequestCode = false
            }
            this.authcodeSendLoading = false
          })
        }
      },
    },

    created() {
      const username = localStorage.getItem('username')
      const rememberMe = localStorage.getItem('rememberLogin')
      if (username) {
        this.login = username
      }
      if (rememberMe) {
        this.rememberMe = rememberMe
      }

      // Give a few seconds for route data to hydrate
      setTimeout(() => {
        if (this.$route.params.view === 'reset') {
          this.isPasswordReset = true
        }
        if (this.$route?.query?.session === 'expired') {
          this.errorText = 'Session expired. Please login.'
          this.sessionExpired = true
          this.loginFailed = true
        }
      }, 519)
    },
  }
</script>
<template>
  <div>
    <v-card v-if="!isAuth" class="mt-10 elevation-12" width="350px" max-width="100%">
      <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
      <v-card-text v-if="isPasswordReset" class="text-center">
        <!-- PASSWORD RESET FORM-->
        <v-form>
          <v-text-field
            outline
            prepend-icon="mdi-shield-alt"
            name="authcode"
            label="Auth Code"
            type="text"
            v-model="authCode"
            hint="received via email"
            hide-details
            class="my-2"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-key"
            name="password"
            label="Password"
            id="password"
            type="password"
            v-model="password"
            :error="password === null || password.length < 8"
            :error-messages="password === null || password.length < 8 ? 'Password should be min. 8 characters' : null"
            class="my-2"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-key"
            name="passwordConf"
            label="Password Confirm"
            id="passwordConf"
            type="password"
            v-model="passwordConf"
            :error="passwordConf !== password"
            :error-messages="passwordConf !== password ? 'Passwords dont match' : null"
            @keyup.enter="loginUser"
          ></v-text-field>
          <v-checkbox v-model="rememberMe" hide-details label="Remember Me" class="mt-0"></v-checkbox>
          <p class="my-4" :class="authcodeText.class">
            {{ authcodeText.text }}
          </p>
          <span>
            <v-text-field
              v-model="username1"
              v-show="isRequestCode"
              prepend-icon="mdi-account"
              name="username1"
              label="Login"
              id="username1"
              type="text"
              :error="username1 === null || username1.length < 3"
              :error-messages="username1 === null || username1.length < 3 ? 'teamabv or email, 3 character min.' : null"
              @keyup.enter="submitAuthcodeRequest"
            >
              <template #append>
                <v-progress-circular v-if="authcodeSendLoading" size="24" color="primary" indeterminate></v-progress-circular>
                <v-icon color="primary" @click="submitAuthcodeRequest" :disabled="username1 === null || username1.length < 3" v-else>mdi-send</v-icon>
              </template>
            </v-text-field>
            <v-btn small @click="authcodeRequestBtn">{{ isRequestCode ? 'CANCEL' : 'REQUEST NEW CODE' }}</v-btn>
          </span>
        </v-form>
      </v-card-text>
      <v-card-text v-else>
        <!-- USER /PASS LOGIN-->
        <v-form>
          <v-text-field prepend-icon="mdi-account" name="login" label="Login" type="text" @keyup.enter="loginUser" v-model="login" hint="email, team abv"></v-text-field>
          <v-text-field
            prepend-icon="mdi-key"
            name="password"
            label="Password"
            id="password"
            type="password"
            @keyup.enter="loginUser"
            v-model="password"
            hint="Bank Accout"
          ></v-text-field>
          <v-checkbox label="Remember Me" v-model="rememberMe"></v-checkbox>
        </v-form>
        <p v-show="loginFailed" class="error--text">
          {{ errorText ? errorText : 'Incorrect Login or Password' }}
        </p>
      </v-card-text>
      <v-card-actions class="mt-4">
        <!-- <v-btn color="primary" @click="testAuth" :loading="loading">test</v-btn> -->
        <v-btn small text color="primary" @click="resetPasswordBtn" :disabled="loading">{{ isPasswordReset ? 'CANCEL' : 'RESET PASSWORD' }}</v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" width="150" @click="loginUser" :loading="loading" :disabled="disabled">{{ isPasswordReset ? 'UPDATE' : 'LOGIN' }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-sheet color="transparent" v-else class="d-flex flex-column align-center justify-center">
      <v-card v-if="sessionExpired || $route?.params?.expired">
        <v-card-text class="text-center">
          <p>Session has expired. Please log back in.</p>
          <v-icon color="warning">mdi-alert</v-icon>
        </v-card-text>
      </v-card>
      <v-btn rounded large color="primary" @click="logoutUser" class="mt-12">LOGOUT</v-btn>
    </v-sheet>
    <!-- <v-btn rounded large color="primary" @click="testDestroy">TEST DESTROY</v-btn> -->

    <!-- <p class="pt-4">{{ isAuth ? 'you are logged in' : 'you need to login'}}</p> -->
  </div>
</template>
<style lang="scss" scoped></style>
