var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-center justify-center s"
  }, [_c('v-avatar', {
    staticClass: "avatar pa-5",
    style: {
      'background-color': 'white',
      border: "3px solid ".concat(_vm.borderColor)
    },
    attrs: {
      "size": "125"
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.image
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }