var render = function render(){
  var _vm$$route, _vm$$route$params;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isAuth ? _c('v-card', {
    staticClass: "mt-10 elevation-12",
    attrs: {
      "width": "350px",
      "max-width": "100%"
    }
  }, [_vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": true
    }
  }) : _vm._e(), _vm.isPasswordReset ? _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-form', [_c('v-text-field', {
    staticClass: "my-2",
    attrs: {
      "outline": "",
      "prepend-icon": "mdi-shield-alt",
      "name": "authcode",
      "label": "Auth Code",
      "type": "text",
      "hint": "received via email",
      "hide-details": ""
    },
    model: {
      value: _vm.authCode,
      callback: function callback($$v) {
        _vm.authCode = $$v;
      },
      expression: "authCode"
    }
  }), _c('v-text-field', {
    staticClass: "my-2",
    attrs: {
      "prepend-icon": "mdi-key",
      "name": "password",
      "label": "Password",
      "id": "password",
      "type": "password",
      "error": _vm.password === null || _vm.password.length < 8,
      "error-messages": _vm.password === null || _vm.password.length < 8 ? 'Password should be min. 8 characters' : null
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-key",
      "name": "passwordConf",
      "label": "Password Confirm",
      "id": "passwordConf",
      "type": "password",
      "error": _vm.passwordConf !== _vm.password,
      "error-messages": _vm.passwordConf !== _vm.password ? 'Passwords dont match' : null
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginUser.apply(null, arguments);
      }
    },
    model: {
      value: _vm.passwordConf,
      callback: function callback($$v) {
        _vm.passwordConf = $$v;
      },
      expression: "passwordConf"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "label": "Remember Me"
    },
    model: {
      value: _vm.rememberMe,
      callback: function callback($$v) {
        _vm.rememberMe = $$v;
      },
      expression: "rememberMe"
    }
  }), _c('p', {
    staticClass: "my-4",
    "class": _vm.authcodeText["class"]
  }, [_vm._v(" " + _vm._s(_vm.authcodeText.text) + " ")]), _c('span', [_c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRequestCode,
      expression: "isRequestCode"
    }],
    attrs: {
      "prepend-icon": "mdi-account",
      "name": "username1",
      "label": "Login",
      "id": "username1",
      "type": "text",
      "error": _vm.username1 === null || _vm.username1.length < 3,
      "error-messages": _vm.username1 === null || _vm.username1.length < 3 ? 'teamabv or email, 3 character min.' : null
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitAuthcodeRequest.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.authcodeSendLoading ? _c('v-progress-circular', {
          attrs: {
            "size": "24",
            "color": "primary",
            "indeterminate": ""
          }
        }) : _c('v-icon', {
          attrs: {
            "color": "primary",
            "disabled": _vm.username1 === null || _vm.username1.length < 3
          },
          on: {
            "click": _vm.submitAuthcodeRequest
          }
        }, [_vm._v("mdi-send")])];
      },
      proxy: true
    }], null, false, 1462687069),
    model: {
      value: _vm.username1,
      callback: function callback($$v) {
        _vm.username1 = $$v;
      },
      expression: "username1"
    }
  }), _c('v-btn', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.authcodeRequestBtn
    }
  }, [_vm._v(_vm._s(_vm.isRequestCode ? 'CANCEL' : 'REQUEST NEW CODE'))])], 1)], 1)], 1) : _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-account",
      "name": "login",
      "label": "Login",
      "type": "text",
      "hint": "email, team abv"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginUser.apply(null, arguments);
      }
    },
    model: {
      value: _vm.login,
      callback: function callback($$v) {
        _vm.login = $$v;
      },
      expression: "login"
    }
  }), _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-key",
      "name": "password",
      "label": "Password",
      "id": "password",
      "type": "password",
      "hint": "Bank Accout"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginUser.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Remember Me"
    },
    model: {
      value: _vm.rememberMe,
      callback: function callback($$v) {
        _vm.rememberMe = $$v;
      },
      expression: "rememberMe"
    }
  })], 1), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loginFailed,
      expression: "loginFailed"
    }],
    staticClass: "error--text"
  }, [_vm._v(" " + _vm._s(_vm.errorText ? _vm.errorText : 'Incorrect Login or Password') + " ")])], 1), _c('v-card-actions', {
    staticClass: "mt-4"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.resetPasswordBtn
    }
  }, [_vm._v(_vm._s(_vm.isPasswordReset ? 'CANCEL' : 'RESET PASSWORD'))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "width": "150",
      "loading": _vm.loading,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.loginUser
    }
  }, [_vm._v(_vm._s(_vm.isPasswordReset ? 'UPDATE' : 'LOGIN'))])], 1)], 1) : _c('v-sheet', {
    staticClass: "d-flex flex-column align-center justify-center",
    attrs: {
      "color": "transparent"
    }
  }, [_vm.sessionExpired || (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$params = _vm$$route.params) !== null && _vm$$route$params !== void 0 && _vm$$route$params.expired ? _c('v-card', [_c('v-card-text', {
    staticClass: "text-center"
  }, [_c('p', [_vm._v("Session has expired. Please log back in.")]), _c('v-icon', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")])], 1)], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mt-12",
    attrs: {
      "rounded": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.logoutUser
    }
  }, [_vm._v("LOGOUT")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }