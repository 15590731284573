import axiosMixins from '@/mixins/api.js'
import Vue from 'vue'

export const frontofficeStore = {
  mixins: [axiosMixins],
  namespaced: false,
  state: {
    auctionhouseAuctions: null,
    auctionhouseAuctionsHistory: [],
    auctionhouseBidNewAuctionKey: null,
    freeagencyAlertDismissed: false,
    freeagencyPlayerAddingAction: null,
    freeagencyPlayerAddingIndex: null,
    freeagencyPlayerPendingAdd: null,
    freeagencyPlayerPendingDrop: null,
    freeagencyPlayerData: null,
    freeagencyPositionLoading: false,
    lineup: {},
    lineupBestballData: {},
    lineupDfsData: {},
    // lineupPlayerChangeOptions: {},
    // lineupPlayerChangePosition: null,
    // lineupPlayerData: {},
    // lineupPlayerGametimeCutoff: null,
    lineupPlayerPendingChange: null,
    matchupData: {},
    playerActionLoading: false,
    playersPendingAdd: [],
    playersWatching: [],
    modalAuctionhouseBidNew: false,
    modalFreeagencyPlayerAdd: false,
    modalLineupPlayerChange: false,

    rosters: null,
    tradecenterTradesData: null,
    transactionHistory: [],
  },
  getters: {
    freeagencyPlayerAdding(state) {
      if (state.freeagencyPlayerAddingAction === 'addPlayer') {
        return state.freeagencyPlayerPendingAdd
      }
      if (state.freeagencyPlayerAddingAction === 'changePlayerDropping') {
        return state.playersPendingAdd[state.freeagencyPlayerAddingIndex]
      }
      if (state.freeagencyPlayerAddingAction === 'withdraw') {
        return state.playersPendingAdd[state.freeagencyPlayerAddingIndex]
      }
    },
    freeagencyPlayersPendingAdd(state) {
      let adding = []
      if (state.playersPendingAdd.length > 0) {
        for (var value of state.playersPendingAdd) {
          adding.push(value['id'])
        }
      }
      return adding
    },
    freeagencyPlayersPendingDrop(state) {
      let dropping = []
      if (state.playersPendingAdd.length > 0) {
        for (var value of state.playersPendingAdd) {
          dropping.push(value.player_dropping['id'])
        }
      }

      return dropping
    },
    tradesIncoming(state, getters, rootState) {
      let trades = []
      let workingTrade = {}
      if (state.tradecenterTradesData && state.tradecenterTradesData.requests.length > 0) {
        // console.log("getter, incoming, pass request.length");
        for (let objKey in state.tradecenterTradesData.requests) {
          workingTrade = state.tradecenterTradesData.requests[objKey]
          workingTrade['tradeKey'] = objKey
          trades.push(workingTrade)
        }
      }
      if (state.tradecenterTradesData && state.tradecenterTradesData.history.length > 0) {
        for (let objKey in state.tradecenterTradesData.history) {
          if (rootState.authenticationStore.userData.id == state.tradecenterTradesData.history[objKey].rec_user) {
            trades.push(state.tradecenterTradesData.history[objKey])
          }
        }
      }

      //APPLY TEAMS FILTER IF SET
      if (rootState.mainStore.filterDropdown1 && Array.isArray(rootState.mainStore.filterDropdown1) && rootState.mainStore.filterDropdown1.length > 0) {
        trades = trades.filter(x => rootState.mainStore.filterDropdown1.indexOf(x.init_user) > -1)
      }

      //APPLY STATUS FILTER IF SET
      if (rootState.mainStore.filterDropdown2 && Array.isArray(rootState.mainStore.filterDropdown2) && rootState.mainStore.filterDropdown2.length > 0) {
        trades = trades.filter(x => rootState.mainStore.filterDropdown2.indexOf(x.status) > -1)
      }

      return trades
    },
    //ON context
    tradesLeague(state, getters, rootState) {
      let tradesActive = []
      let tradesPast = []
      let workingTrade = {}
      if (state.tradecenterTradesData && state.tradecenterTradesData.vote.length > 0) {
        for (let objKey in state.tradecenterTradesData.vote) {
          workingTrade = state.tradecenterTradesData.vote[objKey]

          workingTrade['tradeKey'] = objKey
          tradesActive.push(workingTrade)
        }
      }
      if (state.tradecenterTradesData && state.tradecenterTradesData.league.length > 0) {
        for (let objKey in state.tradecenterTradesData.league) {
          tradesPast.push(state.tradecenterTradesData.league[objKey])
        }
      }
      return tradesActive.concat(tradesPast)
    },
    tradesOutgoing(state, getters, rootState) {
      let trades = []
      let workingTrade = {}
      if (state.tradecenterTradesData && state.tradecenterTradesData.offered.length > 0) {
        for (let objKey in state.tradecenterTradesData.offered) {
          workingTrade = state.tradecenterTradesData.offered[objKey]
          workingTrade['tradeKey'] = objKey
          trades.push(workingTrade)
        }
      }
      if (state.tradecenterTradesData && state.tradecenterTradesData.history.length > 0) {
        for (let objKey in state.tradecenterTradesData.history) {
          if (rootState.authenticationStore.userData.id == state.tradecenterTradesData.history[objKey].init_user) {
            trades.push(state.tradecenterTradesData.history[objKey])
          }
        }
      }

      //APPLY TEAMS FILTER IF SET
      if (rootState.mainStore.filterDropdown1 && Array.isArray(rootState.mainStore.filterDropdown1) && rootState.mainStore.filterDropdown1.length > 0) {
        trades = trades.filter(x => rootState.mainStore.filterDropdown1.indexOf(x.rec_user) > -1)
      }

      //APPLY STATUS FILTER IF SET
      if (rootState.mainStore.filterDropdown2 && Array.isArray(rootState.mainStore.filterDropdown2) && rootState.mainStore.filterDropdown2.length > 0) {
        trades = trades.filter(x => rootState.mainStore.filterDropdown2.indexOf(x.status) > -1)
      }
      //APPLY ORDER FILTER IF SET
      //(TODO)

      return trades
    },
  },
  mutations: {
    freeagencyInitializePageData(state) {},
    freeagencyGetPlayers(state, data) {
      state.freeagencyPlayerData = data
    },
    freeagencyPlayerAdd(state, data) {
      state.playersPendingAdd.push(data)
    },
    freeagencyPlayerAddingRemove(state, data) {
      Vue.delete(state.playersPendingAdd, data)
    },
    freeagencySetPageData(state, data) {
      state[data.stateKey] = data.data
    },
    freeagencyPlayerAddUpdateDropping(state, data) {
      Vue.set(state.playersPendingAdd[data.playerAddingKey], 'player_dropping', {
        ...data.playerDropping,
      })
    },
    rostersGetRosters(state, data) {
      state.rosters = data
    },
    setFreeagencyAlertDismissed(state, action) {
      state.setFreeagencyAlertDismissed = true
    },
    setFreeagencyPlayerAddingAction(state, action) {
      state.freeagencyPlayerAddingAction = action
    },
    setFreeagencyPlayerAddingIndex(state, index) {
      state.freeagencyPlayerAddingIndex = index
    },
    setFreeagencyPlayerPendingAdd(state, player) {
      Vue.set(state, 'freeagencyPlayerPendingAdd', player)
    },
    setLineupPlayerChangePosition(state, position) {
      // console.log(position);
      Vue.set(state, 'lineupPlayerChangePosition', position)
      // console.log(state.lineupPlayerChangePosition);
    },
    togglePlayerActionLoading(state) {
      state.playerActionLoading = !state.playerActionLoading
    },
    toggleModalLineupPlayerChange(state) {
      state.modalLineupPlayerChange = !state.modalLineupPlayerChange
    },
    toggleModalAuctionhouseBidNew(state, option) {
      if (option !== null) {
        state.modalAuctionhouseBidNew = option
      } else {
        state.modalAuctionhouseBidNew = !state.modalAuctionhouseBidNew
      }
      // CLEAR VALUES RELATED TO MODAL
      if (!state.modalFreeagencyPlayerAdd) {
        state.freeagencyPlayerAddingAction = null
        state.freeagencyPlayerAddingIndex = null
      }
    },
    toggleModalFreeagencyPlayerAdd(state, option) {
      if (option) {
        state.modalFreeagencyPlayerAdd = option
      } else {
        state.modalFreeagencyPlayerAdd = !state.modalFreeagencyPlayerAdd
      }
      // CLEAR VALUES RELATED TO MODAL
      if (!state.modalFreeagencyPlayerAdd) {
        state.freeagencyPlayerAddingAction = null
        state.freeagencyPlayerAddingIndex = null
      }
    },
    toggleModalOverflowAdd(state, option) {
      state.overflow
    },
    tradecenterInitializePageData(state, data) {
      // console.log("tradecenterInitializePageData mutation");
      if (data.rosters) {
        state.rosters = data.rosters
      }
      if (data.tradesData) {
        state.tradecenterTradesData = data.tradesData
        //TESTING: ENABLE ABILITY TO TEST VOTING
        // state.tradecenterTradesData.league[0].usersVote = null
        //state.tradecenterTradesData.history[4].status = 'PENDING'

        //TESTING: ENABLE ABILITY TO TRADE RESPONSE
        // state.tradecenterTradesData.requests.push(state.tradecenterTradesData.history[4])
        // state.tradecenterTradesData.requests[0].status = 'PENDING'
        // console.log(state.tradecenterTradesData.history[4])
        // state.tradecenterTradesData.history.splice(4, 1)

        //TESTING: ENABLE ABILITY TO WITHDRAW TRADE
        // state.tradecenterTradesData.requests.push(state.tradecenterTradesData.history[4])
        // state.tradecenterTradesData.history[0].status = 'PENDING'
        // state.tradecenterTradesData.offered.push(state.tradecenterTradesData.history[0])
        // // state.tradecenterTradesData.requests[0].status = 'PENDING'
        // // console.log(state.tradecenterTradesData.history[4])
        // state.tradecenterTradesData.history.splice(0, 1)
      }
    },
    tradecenterTradeAccept(state, data) {
      //data expects requestKey, key to set to vote
      //data expects trade, push to vote trades
      Vue.set(state.tradecenterTradesData['requests'], data.tradeKey, data.trade)
      state.tradecenterTradesData['vote'].push(data.trade)
    },
    tradecenterTradeAddOffered(state, data) {
      state.tradecenterTradesData.offered.push(data)
    },
    tradecenterTradeAddVote(state, data) {
      state.tradecenterTradesData.vote.push(data)
    },
    tradecenterTradeReject(state, data) {
      Vue.set(state.tradecenterTradesData.requests[data], 'status', 'REJECTED')
    },
    tradecenterTradeVote(state, data) {
      // console.log("tradecenterTradeVote mutation");
      if (data.status == 'success') {
        if (data.vote == 'yay') {
          Vue.set(state.tradecenterTradesData.league[data.tradeKey], 'usersVote', 'yes')
          Vue.set(state.tradecenterTradesData.league[data.tradeKey], 'yes', parseInt(state.tradecenterTradesData.league[data.tradeKey]['yes']) + 1)
        }
        if (data.vote == 'nay') {
          Vue.set(state.tradecenterTradesData.league[data.tradeKey], 'usersVote', 'no')
          Vue.set(state.tradecenterTradesData.league[data.tradeKey], 'no', parseInt(state.tradecenterTradesData.league[data.tradeKey]['no']) + 1)
        }
      }
    },
    tradecenterTradeWithdraw(state, data) {
      // console.log("tradecenterTradeWithdraw mutation");
      if (data.status === 'success') {
        Vue.set(state.tradecenterTradesData.offered[data.tradeKey], 'status', 'WITHDRAWN')
        //TODO: UPDATE UPDATED TIMESTAMP (For accurate popover text)
      }
    },
  },
  actions: {
    setFreeagencyAlertDismissed(context) {
      context.commit('setFreeagencyAlertDismissed')
    },

    freeagencyGetPlayers(context, data) {
      return new Promise((resolve, reject) => {
        axiosMixins.callApi(data['uri']).then(response => {
          if (response) {
            if (data.type === 'all') {
              resolve(response)
            } else {
              context.commit('freeagencyGetPlayers', response)
              resolve()
            }
          }
        })
      })
    },
    freeagencyPlayerActions(context, data) {
      // console.log("freeagencyPlayerActions ACTION");
      // console.log(data);
      const acceptedActions = ['addOverflow', 'addPlayer', 'changePlayerDropping', 'withdraw']
      if (acceptedActions.indexOf(data.action) < 0) {
        // console.log("ERROR UNKNOWN ACTION OF: " + data.action);
        context.dispatch('toggleSnackbar', {
          status: 'error',
          message: 'ERROR: unknown action <br />Please report',
        })
      }
      context.commit('setFreeagencyPlayerAddingAction', data.action)
      context.commit('setFreeagencyPlayerPendingAdd', {
        ...data.playerPendingAdd,
      })

      if (data.action === 'addOverflow' || data.action === 'addPlayer' || data.action === 'changePlayerDropping') {
        context.commit('toggleModalFreeagencyPlayerAdd')
      }

      if (data.action === 'withdraw') {
        context.commit('toggleModalFreeagencyPlayerAdd')
      }
    },
    freeagencyPlayerAdd(context, data) {
      return new Promise((resolve, reject) => {
        axiosMixins.callApi(`freeagency_player_add/${data.playerAdding.id}/${data.playerDropping.id}`).then(response => {
          if (response) {
            let responseData = {}
            responseData = {
              status: response.status,
              message: response.message,
              visible: true,
            }
            if (response.status === 'success') {
              let newPlayerAdding = {}
              newPlayerAdding = context.state.freeagencyPlayerPendingAdd
              newPlayerAdding['player_dropping'] = data.playerDropping
              context.commit('freeagencyPlayerAdd', newPlayerAdding)

              // console.log(context.state.playersPendingAdd);
              context.state.freeagencyPlayerPendingAdd = null
              context.state.freeagencyPlayerPendingDrop = null
              context.state.freeagencyPlayerAddingAction = null
              context.dispatch('toggleModalFreeagencyPlayerAdd')
            }
            context.dispatch('toggleSnackbar', responseData)
          }

          //context.commit('freeagencyPlayerAdd', data)
          resolve()
        })
      })
    },
    freeagencyPlayerAddoverflow(context, data) {
      // console.log("freeagencyPlayerAddoverflow");
      // console.log(data);
      return new Promise((resolve, reject) => {
        axiosMixins
          .callApi('freeagency_player_add_overflow', {
            changes: JSON.stringify(data),
          })
          .then(response => {
            if (response) {
              // console.log(response)
              let responseData = {}
              responseData = {
                status: response.status,
                message: response.message,
                visible: true,
              }
              if (response.status === 'success') {
                if (response.data && response.data.roster) {
                  context.commit('lineupSetLineupData', response.data.roster)
                  //update roster overflow
                }
                if (response.data && response.data.overflow !== undefined) {
                  // console.log(response)
                  context.dispatch('notificationSet', {
                    notificationType: 'rosterOverflow',
                    data: response.data.overflow,
                  })
                  //update roster overflow
                }
                context.state.freeagencyPlayerPendingAdd = null
                context.state.freeagencyPlayerPendingDrop = null
                context.state.freeagencyPlayerAddingAction = null
                context.dispatch('toggleModalFreeagencyPlayerAdd')
              }
              context.dispatch('toggleSnackbar', responseData)
            }

            //context.commit('freeagencyPlayerAdd', data)
            resolve()
          })
      })
    },
    freeagencyPlayerWithdraw(context, data) {
      // console.log("freeagencyPlayerWithdraw");
      return new Promise((resolve, reject) => {
        //PICK UP HERE MOVING FROM MUTATION
        axiosMixins.callApi(`freeagency_player_add_withdraw/${data.playerAdding}`).then(response => {
          if (response.status === 'success') {
            // context.commit('freeagency')
            let playerAddingKey = context.state.playersPendingAdd.findIndex(el => el.id === data.playerAdding)
            // console.log(playerAddingKey);
            // console.log(data.playerAdding);
            // console.log(context.state.playersPendingAdd);
            if (playerAddingKey > -1) {
              context.commit('freeagencyPlayerAddingRemove', playerAddingKey)
            } else {
              // console.log("player adding index not found");
            }
          }
          context.dispatch('toggleModalFreeagencyPlayerAdd')
          context.dispatch('toggleSnackbar', response)
          resolve()
        })
      })
    },
    freeagencyUpdatePlayerDropping(context, data) {
      // console.log("freeagencyUpdatePlayerDropping");
      return new Promise((resolve, reject) => {
        //PICK UP HERE MOVING FROM MUTATION
        axiosMixins.callApi(`freeagency_player_add_change/${data.playerAdding.id}/${data.playerDropping.id}`).then(response => {
          if (response.status === 'success') {
            let playerAddingKey = context.state.playersPendingAdd.findIndex(el => el.id === data.playerAdding.id)
            // console.log(playerAddingKey);
            // console.log(data.playerAdding);
            // console.log(context.state.playersPendingAdd);
            if (playerAddingKey > -1) {
              context.commit('freeagencyPlayerAddUpdateDropping', {
                playerAddingKey: playerAddingKey,
                playerDropping: data.playerDropping,
              })
            } else {
              // console.log("player adding index not found");
            }
          }
          context.dispatch('toggleModalFreeagencyPlayerAdd')
          context.dispatch('toggleSnackbar', response)
          resolve()
        })
      })
      //
      //   let newPlayerData =
      //   state.playersPendingAdd[state.freeagencyPlayerAddingIndex]
      // newPlayerData['player_dropping'] = state.lineupPlayerData[data]
      // Vue.set(
      //   state.playersPendingAdd,
      //   state.freeagencyPlayerAddingIndex,
      //   newPlayerData
      // )
    },
    lineupChangePlayerSubmit(context, data) {
      // console.log("lineupChangePlayerSubmit");
      // console.log(data);
      return new Promise((resolve, reject) => {
        //PICK UP HERE MOVING FROM MUTATION
        axiosMixins.callApi('lineup_set_lineup', { changes: JSON.stringify(data) }).then(response => {
          // console.log(response)
          if (response) {
            // console.log(response);
            if (response.status === 'success') {
              context.commit('toggleModalLineupPlayerChange')
            }
            context.dispatch('toggleSnackbar', {
              status: response.status,
              message: response.message,
            })
            if (response.data && response.data.roster) {
              context.commit('lineupSetLineupData', response.data.roster)
              //update roster overflow
            }
            if (response.data && response.data.overflow) {
              context.dispatch('notificationSet', {
                notificationType: 'rosterOverflow',
                data: response.data.overflow,
              })
              //update roster overflow
            }
          }
          //context.commit('freeagencyPlayerAdd', data)
          resolve()
        })
      })
    },
    rostersGetRosters() {
      return new Promise((resolve, reject) => {
        // console.log("rostersGetRosters");
        let uri = 'rosters_get_roster/all'
        axiosMixins.callApi(uri).then(
          response => {
            context.commit('rostersGetRosters', response)
            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    setLineupPlayerChangePosition(context, position) {
      context.commit('setLineupPlayerChangePosition', position)
    },
    togglePlayerActionLoading(context) {
      context.commit('playerActionLoading')
    },
    toggleModalAuctionhouseBidNew(context) {
      context.commit('toggleModalAuctionhouseBidNew')
    },
    toggleModalLineupPlayerChange(context, options) {
      context.commit('toggleModalLineupPlayerChange')
    },
    toggleModalFreeagencyPlayerAdd(context, options) {
      context.commit('toggleModalFreeagencyPlayerAdd')
    },
    toggleModalOverflowAdd(context, options) {
      //TOGGLE TRUE, SET PLAYERS
      context.commit('toggleModalOverflowAdd', options)
    },
    tradecenterInitializePageData(context) {
      return new Promise((resolve, reject) => {
        // console.log("tradecenterInitializePageData");
        let uri = 'trade_get_page_data'
        axiosMixins.callApi(uri).then(
          response => {
            context.commit('tradecenterInitializePageData', response)
            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    tradecenterTradeRespond(context, data) {
      return new Promise((resolve, reject) => {
        console.log('tradecenterTradeRespond')
        // console.log(data)
        let uri = `trade_${data.response}/${data.trade.id}`
        axiosMixins.callApi(uri).then(
          response => {
            if (response.status === 'success') {
              if (data.response === 'reject') {
                context.commit('tradecenterTradeReject', data.tradeKey)
              }
              if (data.response === 'accept') {
                context.commit('tradecenterTradeAccept', {
                  requestKey: data.tradeKey,
                  trade: response.data,
                })
              }
            }

            // if (data.response == 'accept') {
            //   Vue.set(
            //     state.tradecenterTradesData.requests[data.tradeKey],
            //     'status',
            //     'VOTE'
            //   )
            //   state.tradecenterTradesData.vote.push(
            //     state.tradecenterTradesData.requests[data.tradeKey]
            //   )
            //   Vue.delete(state.tradecenterTradesData.requests, data.tradeKey)
            // }context.commit('tradecenterTradeAccept', {requestKey: data.tradeKey, trade: response.data})

            // const responseData = { ...data, ...response }
            //If context is nott coming from dialog, update trade data
            //TODO: context mutation logic needs to be moved to here anyway
            //TODO WHAT IS THIS...
            // if (!data.fromDialog) {
            //   context.commit('tradecenterTradeRespond', data)
            //   context.dispatch('toggleSnackbar', { data: responseData })
            // }
            context.dispatch('toggleSnackbar', {
              status: response.status,
              message: response.message,
            })
            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    tradecenterTradeSubmitNew(context, data) {
      // console.log("freeagencyPlayerAddoverflow");
      // console.log(data);
      return new Promise((resolve, reject) => {
        axiosMixins
          .callApi('trade_new_trade', {
            changes: JSON.stringify(data),
          })
          .then(response => {
            // console.log(response)
            if (response.status === 'success') {
              context.commit('tradecenterTradeAddOffered', response.data)
              context.dispatch('toggleSnackbar', {
                status: response.status,
                message: response.message,
              })
            }
            resolve()
          })
      })
    },
    tradecenterTradeVote(context, data) {
      return new Promise((resolve, reject) => {
        // console.log("tradecenterTradeVote");
        let uri = `trade_vote/${data.trade.id}/${data.vote}`
        axiosMixins.callApi(uri).then(
          response => {
            const responseData = { ...data, ...response }
            context.commit('tradecenterTradeVote', responseData)
            context.dispatch('toggleSnackbar', { data: responseData })

            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    tradecenterTradeWithdraw(context, data) {
      return new Promise((resolve, reject) => {
        // console.log("tradecenterTradeWithdraw");
        let uri = `trade_withdraw/${data.trade.id}`
        axiosMixins.callApi(uri).then(
          response => {
            const responseData = { ...data, ...response }
            context.commit('tradecenterTradeWithdraw', responseData)
            context.dispatch('toggleSnackbar', {
              status: response.status,
              message: response.message,
            })

            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    },
  },
}
