<template>
  <v-app>
    <v-main class="overflow-hidden">
      <v-container class="login d-flex flex-column align-center justify-start">
        <v-sheet color="transparent">
          <v-img
            contain
            height="auto"
            width="384"
            :src="isAuth ? `${media_source}/images/avatars/${userData.avatar}` : `${media_source}/images/wosfl_logo.svg`"
            class="logo flex-shrink-1"
          ></v-img>
        </v-sheet>
        <LoginCard></LoginCard>
        <v-dialog v-model="errorModal" scrollable fullscreen persistent max-width="400px" transition="dialog-transition">
          <v-card>
            <v-card-title primary-title class="title primary--text align-center justify-center">WELCOME BACK</v-card-title>
            <v-card-text>
              <p>Welcome back to another year of WOSFL.</p>
              <p class="mt-2">The login system has been updated this off season and requires you to reset your password.</p>
              <p>An email has been sent with a temporary, time sensitive authentication code.</p>
              <p class="mt-2">Please close this dialog and select reset password to initiate the reset.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large text color="primary" @click="errorModal = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  import LoginCard from '@/components/Login/LoginCard.vue'
  export default {
    name: 'Login',
    components: {
      LoginCard,
    },
    data: () => ({
      media_source: process.env.VUE_APP_MEDIA_URL,
    }),
    computed: {
      ...mapState({
        isAuth: state => state.authenticationStore.isAuth,
        userData: state => state.authenticationStore.userData,
      }),
      errorModal() {
        return false
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login {
    height: 100vh;
    background-color: #a4aab0bf;
    width: 100vw;
  }
  /* .logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
  } */
</style>
