<template>
  <component :is="componentLoaded" v-bind="componentLoadedData"></component>
</template>

<script>
  import { mapState } from 'vuex'

  import login from '@/components/Login/Login.vue'
  // import MainContent from '@/App_content'
  import Statuspage from '@/components/Login/Statuspage.vue'
  import PageLoader from '@/components/Global/Pageloader'

  export default {
    name: 'App',
    components: {
      // MainContent,
      mainContent: () => import(/* webpackChunkName: "AppContent" */ './App_content.vue').then(),
      login,
      PageLoader,
      Statuspage,
    },
    data() {
      return {}
    },
    created() {
      //TODO: move to mixin
      this.$vuetify.theme.primary = '#1A237E'
      this.$vuetify.theme.secondary = '#fff'
      this.$vuetify.theme.accent = '#D50000'
      this.$vuetify.theme.error = '#D50000'
      this.$vuetify.theme.info = '#2196F3'
      this.$vuetify.theme.success = '#4CAF50'
      this.$vuetify.theme.warning = '#FFC107'
      this.$vuetify.theme.disabled = '#545b77'
      console.log(process.env)
    },
    computed: {
      ...mapState({
        authenticated: state => state.authenticationStore.isAuth,
        firstLoad: state => state.authenticationStore.firstLoad,
        siteStatus: state => state.authenticationStore.siteStatus,
        siteStatusMessage: state => state.authenticationStore.siteStatusMessage,
      }),
      componentLoaded() {
        if (this.firstLoad) {
          return 'PageLoader'
        } else if (this.authenticated) {
          if (this.siteStatus !== null) {
            return 'Statuspage'
          } else {
            return 'mainContent'
          }
        } else {
          return 'login'
        }
      },
      componentLoadedData() {
        if (this.siteStatus !== null) {
          // ['icon', 'height', 'loading', 'text', 'link', 'avatar']
          return {
            text: this.siteStatusMessage,
          }
        }
        return {}
      },
      //debugging
      breakpointName() {
        return this.$vuetify.breakpoint.name
      },
      routename() {
        return this.$route.name
      },
      vuetify() {
        return this.$vuetify
      },
    },
    watch: {},
    created() {},
  }
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');

  $body-font-family: 'Montserrat';
  $title-font: 'Montserrat';
  .v-application {
    font-family: $body-font-family, sans-serif !important;
    .title {
      // To pin point specific classes of some components
      font-family: $title-font, sans-serif !important;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
</style>
<style>
  /* GLOBAL*/
  * {
    box-sizing: border-box;
  }

  .tempMenuButton {
    position: fixed;
    top: 2px;
    right: 1px;
    z-index: 2;
  }

  /* .v-navigation-drawer {
  margin-top: 50px !important;
} */

  .navDrawSubmenuItem .v-badge--overlap .v-badge__badge {
    top: -8px;
    right: -16px !important;
  }

  .marquee {
    width: 100%;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
  }

  .marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 40s linear infinite;
    margin-bottom: 0px;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  .active {
    color: #1a237e !important;
  }
</style>
