import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
const { config } = Vue
export default {
  async callApi(uri, postData, urlMod = 'default') {
    // TODO: REFACTOR THIS MESS
    const baseUrls = {
      admin: process.env.VUE_APP_API_URL + 'admin/',
      cli: process.env.VUE_APP_API_URL + 'cli/',
      commish: process.env.VUE_APP_API_URL + 'api_commish/',
      default: process.env.VUE_APP_API_URL + 'api/',
      fqdn: '',
    }
    // const baseUrls = {
    //   default: process.env.VUE_APP_API_URL + 'api/',
    //   commish: process.env.VUE_APP_API_URL + 'api_commish/',
    // }
    let endpoint = baseUrls[urlMod] + uri
    const axiosOptions = this.axiosDefaultPostOptions()
    if (postData) {
      let params = null
      if (postData instanceof FormData) {
        params = postData
        axiosOptions.headers['Content-Type'] = 'multipart/form-data'
      } else {
        params = new URLSearchParams()
        for (let key in postData) {
          params.append(key, postData[key])
        }
      }
      axios.defaults.withCredentials = true
      return axios
        .post(endpoint, params, axiosOptions)
        .then(data => {
          // console.log(data.statusText)
          if (data?.status >= 400) {
          // if (data?.statusText !== 'OK') {
            console.log(data)
            throw Error('error getting data')
          }
          if (data.data === 'unfound19') {
            window.location.assign('/login?session=expired')
          } else {
            return data.data
          }
        })
        .catch(err => {
          console.log(`error getting ${endpoint}`)
          console.error(err.message)
          throw Error(err)
        })
    } else {
      return axios
        .get(endpoint, this.axiosDefaultPostOptions())
        .then(data => {
          // console.log(data.statusText)
          if (data?.status >= 400) {
          // if (data?.statusText !== 'OK') {
            console.log(data)
            throw Error('error getting data')
          }

          if (data.data === 'unfound19') {
            console.log(router)
            window.location.assign('/login?session=expired')
          } else {
            return data.data
          }
        })
        .catch(err => {
          console.log(`error getting ${endpoint}`)
          console.error(err.message)
          throw Error(err)
        })
    }
  },

  checkAuth() {
    axios.get('https://www.wosfl.com/api/global_check_auth', {
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'include',
    })
  },
  axiosDefaultOptions() {
    axios.defaults.withCredentials = true
    var options = {
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'include',
    }
    return options
  },
  axiosDefaultPostOptions() {
    axios.defaults.withCredentials = true
    var options = {
      mode: 'no-cors',
      headers: {
        // 'Content-Type': 'multipart/form-data', //SET in callApi if formData is passed
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
      credentials: 'include',
    }
    return options
  },
}
