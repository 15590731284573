<template>
  <v-app>
    <v-main>
      <v-app-bar app :color="$vuetify.theme.themes.light.primary" class="justify-space-between align-center font-weight-black secondary--text" clipped-right ref="topToolbar">
      </v-app-bar>
      <v-img contain width="384" src="https://www.wosfl.com/images/wosfl_logo.svg" class="logo"></v-img>
      <div class="login d-flex flex-column  justify-center align-center align-content-center">
        <placeHolder :text="siteStatusMessage" height="10"></placeHolder>
      </div>
      <v-footer app height="56" :color="$vuetify.theme.themes.light.primary"> </v-footer>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  import placeHolder from '@/components/Global/Placeholder_card'
  export default {
    name: 'StatusPage',
    components: {
      placeHolder,
    },
    computed: {
      ...mapState({
        isAuth: state => state.authenticationStore.isAuth,
        userData: state => state.authenticationStore.userData,
        siteStatus: state => state.authenticationStore.siteStatus,
        siteStatusMessage: state => state.authenticationStore.siteStatusMessage,
      }),
      errorModal() {
        return false
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login {
    height: calc(100vh - 110px);
    background-color: #a4aab0bf;
  }
  .logo {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
  }
</style>
